import React from 'react';
import styles from './RecuperarSenha.module.css';
import Input from '../forms/Input'
import Button from '../forms/Button'
import firebase from '../../Utils/Firebase';
import 'firebase/auth'
import Head from '../Head';


const RecuperarSenha = () => {

  const [username, setUsername] = React.useState('');
  const [msg, setMsg] = React.useState('');


  function recuperarSenha() {
    firebase.auth().sendPasswordResetEmail(username).then((resposta) => {
      setMsg('Enviamos um link no seu email para você redefinir sua senha!');
    }).catch(erro => {
      setMsg('Verifique se o email está correto!')
    })
  }

  return (
    <>
    <Head title="Cantinho d' Bolos | Recuperar Senha" description="Esses São nossos produtos." />
      <form className={`${styles.form} animeLeft`}>
        <div className={styles.logTitulo}>
          <h1>Recuperar Senha</h1>
        </div>
        <Input onChange={(e) => setUsername(e.target.value)} name="username" id={username} label="Usuário"  type="email"/>
        
        <Button type="button" onClick={recuperarSenha}>Recuperar</Button>
        <div className={styles.msgLogin}>
        <span >{msg}</span>
        </div>
      </form>
    </>
  );
};

export default RecuperarSenha;
