import firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDKC7vXmpoPpnoZHSuS6HuJ7oxAXf-qvbs",
  authDomain: "cantinhodbolos-a1ee2.firebaseapp.com",
  projectId: "cantinhodbolos-a1ee2",
  storageBucket: "cantinhodbolos-a1ee2.appspot.com",
  messagingSenderId: "826636295094",
  appId: "1:826636295094:web:357510839a1fa1ae2a3f17"
};

export default firebase.initializeApp(firebaseConfig);