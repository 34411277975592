import React from 'react'
import useFetchProdutos from './useFetchProdutos'
import Card from './Card'
import Head from '../Head'
import BtnWhatsapp from '../BtnWhatsapp';


const Outros = () => {

  const {outros} = useFetchProdutos()

  if(outros === null) return null; 
  return (
    <>
      <BtnWhatsapp />
       <Head title="Cantinho d' Bolos | Outros" description="Esses São nossos produtos." />
      {outros.map(item => <Card key={item.id} id={item.id} img={item.foto} nome={item.nome} precoG={item.precoG} precoM={item.precoM} precoP={item.precoP} tipo={item.tipo} usuario={item.usuario} visualizacoes={item.visualizacoes} descricao={item.descricao} /> )}
    </>
  )
}

export default Outros
