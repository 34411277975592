import React from 'react';
import styles from './NavbarLogin.module.css';
import { Link } from 'react-router-dom';
import Funcionamento from '../funcionamento/Funcionamento';
import { useSelector, useDispatch } from 'react-redux';

const NavbarLogin = () => {
  const usuarioLogado = useSelector((state) => state.usuarioEmail);
  const usuarioGina = 'ginasobraladv@yahoo.com';


  // console.log(cadastroId)
  
  const dispatch = useDispatch();
  return (
    <>
      <div className={styles.navbarLogin}>
        <ul>
          <li>
            <Funcionamento />
          </li>
          {useSelector((state) => state.usuarioLogado) > 0 &&
          usuarioLogado === usuarioGina ? (
            <>
              <Link to="/publicarproduto">
                <li> Publicar</li>
              </Link>
              <Link to="/estatisticas">
                <li> Estatísticas</li>
              </Link>
              <Link to="/pontos">
                <li> Pontos</li>
              </Link>

              <Link to="" onClick={() => dispatch({ type: 'LOG_OUT' })}>
                <li> Sair</li>
              </Link>
            </>
          ) : (
            <>
              {usuarioLogado ? (
                <>
                 <Link to="perfil">
                  <li> Perfil </li>
                </Link>
                <Link to="" onClick={() => dispatch({ type: 'LOG_OUT' })}>
                  <li> Sair</li>
                </Link>
                </>
              ) : (
                <Link to="login">
                  <li> Login</li>
                </Link>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default NavbarLogin;
