import React from 'react';
import useFetchProdutos from './useFetchProdutos';
import Card from './Card';
import Head from '../Head';
import BtnWhatsapp from '../BtnWhatsapp';

const PaesItalianos = () => {
  const { paes } = useFetchProdutos();

  if (paes === null) return null;
  return (
    <>
      <BtnWhatsapp />
      <Head
        title="Cantinho d' Bolos | Pães Italianos"
        description="Esses São nossos produtos."
      />
      {paes.map((item) => (
        <Card
          key={item.id}
          id={item.id}
          img={item.foto}
          nome={item.nome}
          precoG={item.precoG}
          precoM={item.precoM}
          precoP={item.precoP}
          tipo={item.tipo}
          usuario={item.usuario}
          visualizacoes={item.visualizacoes}
          descricao={item.descricao}
        />
      ))}
    </>
  );
};

export default PaesItalianos;
