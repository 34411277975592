import React from 'react'
import styles from './Modal.module.css'

const Modal = ({id = 'modal', onClose =() => {}, children}) => {



  const handleOutSideClose = ({target}) => {
    if(target.id === id) onClose()

  }

  return (
    <div id={id} className={styles.modal} onClick={handleOutSideClose}>
      <div className={styles.container}>
        <button className={styles.close} onClick={onClose}>Entendi...</button>
          <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default Modal
