import React from 'react';
import styles from './Contato.module.css';
import Head from '../Head';
import contact from '../../Assets/contact_us.svg';
import whatsIcon from '../../Assets/iconeWhats.png';
import InstIcon from '../../Assets/iconeinsta.png';
import faceIcon from '../../Assets/iconfacebook.png';
import telIcon from '../../Assets/iconphone.png';
import Location from '../location/Location';


const Contato = () => {
  return (
    <section className={`${styles.contato} animeLeft`}>
      <Head
        title="Cantinho d' Bolos | Contato"
        description="Entre em contato."
      />
      <img src={contact} alt="img contato" />
      <div>
        <h1 className={styles.title}>Entre em contato.</h1>
        <ul className={styles.dados}>
            <a  href="https://api.whatsapp.com/send?phone=5579999779613&text=Texto%20aqui" target="_blank">
          <li className={styles.textoIcone}>
              <img
                className={styles.Icon}
                src={whatsIcon}
                alt="icone whatsapp"
              />
            (79)999779613
          </li>
            </a>
          <li className={styles.textoIcone}>
            <a href="/">
              <img className={styles.Icon} src={telIcon} alt="icone tele" />
            </a>
            (79)33035118
          </li>
            <a href="https://www.instagram.com/cantinhodbolos/">
          <li className={styles.textoIcone}>
              <img
                className={styles.Icon}
                src={InstIcon}
                alt="icone whatsapp"
                />
                instagram.com/cantinhodbolos
          </li>
            </a>
            <a href="https://www.facebook.com/cantinhodbolos/">
          <li className={styles.textoIcone}>
              <img
                className={styles.Icon}
                src={faceIcon}
                alt="icone whatsapp"
              />
            facebook.com/cantinhodbolos/
          </li>
            </a>
          <li className={styles.textoIcone}>
            {/* <img className={styles.Icon} src={locationIcon} alt="" /> */}
            Rua Tenisson Ribeiro, 563 <br></br>
            Salgado Filho.
          </li>
        </ul>
      </div>
      <div className={styles.map}>
        <div className={styles.location}>
          <Location />
        </div>
        <div className={styles.efeito}></div>
      </div>
    </section>
  );
};

export default Contato;
