import React from 'react';
import Carousel from 'react-elastic-carousel';
import Slide from '../slide/Slide';
import logo from '../../Assets/logocantinho.png';

import styles from './ProdutosHome.module.css';
import fotoTorta from '../../Assets/tortacapa.png';
import fotoBolos from '../../Assets/capaBolos.jpg';
import fotoChocolate from '../../Assets/chocolate.png';
import fotoBaila from '../../Assets/bailaMesclada.png';
import fotoFormig from '../../Assets/formigueiro.png';
import fotoOvos from '../../Assets/ovos1.png';
import fotoCenoura from '../../Assets/cenoura.png';
import fotoRed from '../../Assets/red.png';
import fotoOreo from '../../Assets/oreo.png';

import fotoBoloRomeu from '../../Assets/romeu2.png';
import fotoVulcao from '../../Assets/vulcaoBrigadeiro.png';
import fotoQueijo from '../../Assets/queijo.png';
import fotoRocombole from '../../Assets/rocombole.png';
import fotoPaes from '../../Assets/capaPaesItalianos.jpg';
import fotoOutros from '../../Assets/capaOutros.jpg';
import { Link } from 'react-router-dom';
import Head from '../Head';
import BtnWhatsapp from '../BtnWhatsapp';
import { useSelector } from 'react-redux';
import Modal from '../forms/Modal';
import useFetchProdutos from './useFetchProdutos';


export const ProdutosHome = () => {
  const [modalVisible, setModalVilible] = React.useState(false);

  const {carregando} = useFetchProdutos();

  React.useEffect(() => {
    if(carregando > 0) {
      setModalVilible(true)
    }
  }, [carregando])

  const breakPoints = [
    { width: 1, itemsToShow: 2 },
    { width: 500, itemsToShow: 4 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];

  const style = {};

  return (

    
    
    <>
    
      <BtnWhatsapp />

      <div className={` ${styles.titleCarousel} animeLeft`}>
        <h1>Delícias de todo dia!!!</h1>
      </div>

      <Carousel
        itemPadding={[0, 3]}
        enableAutoPlay
        autoPlaySpeed={3000}
        breakPoints={breakPoints}
        style={style}
        className={styles.carousel}
        >
       <Link to="/produtodetalhes/n5adKOWvN9vftphsEJYN"> <Slide texto="Romeu e Julieta" img={fotoBoloRomeu} /> </Link>

        <Link  to="/produtodetalhes/VpbDyof2pngjpJSw2WhK"> <Slide texto="Bolo de Queijo" img={fotoQueijo} /></Link>

        <Link  to="/produtodetalhes/bqU1tbfev6flpGhgokuv"> <Slide texto="Bolo de Chocolate" img={fotoChocolate} /></Link>

        <Link  to="/produtodetalhes/lFWZvR0bGy0bCWj96Yof"> <Slide texto="Bolo de Vulcão" img={fotoVulcao} /></Link>

        
        <Link  to="/produtodetalhes/CUx2ZyWW4zbOGkIqaBJZ"> <Slide texto="Bailarina Mesclada" img={fotoBaila} /></Link>

        <Link  to="/produtodetalhes/pSoqmvpzfSytGEneVC0V"> <Slide texto="Bolo Formigueiro" img={fotoFormig} /></Link>

        <Link  to="/produtodetalhes/IFtNLucNx9UICOcU5EDG"> <Slide texto="Bolo de Ovos" img={fotoOvos} /></Link>

        <Link  to="/produtodetalhes/wfmgFCx8zBTfhaPXRIiy"> <Slide texto="Cenoura c/ Ganache" img={fotoCenoura} /></Link>

        <Link  to="/produtodetalhes/oKHGSRnBW60QRGFBegux"> <Slide texto="Bailarina Red Velvet" img={fotoRed} /></Link>

        <Link  to="//produtodetalhes/saZ3tzqmT2BPmeaILnPR"> <Slide texto="Bailarina Oreo" img={fotoOreo} /></Link>
       
      </Carousel>

        {useSelector((state) => state.usuarioLogado) > 0 ? null : modalVisible ? <Modal onClose={() => setModalVilible(false)}>
          <img src={logo} alt="" />
      <h1> Obrigado por nos visitar!...</h1> <p>Participe do Programa de pontos. A cada <strong>R$20,00</strong>, receba <strong>R$1,00</strong> de volta em sua próxima compra!
         <br></br> <Link to="regras" className={styles.regras} href="#">ver regras</Link> </p>
         
         
         <div className={styles.opcaoLogin}>
        <a href="/login" className={styles.loginParagrafo}>
          Login
        </a>
        <span>&#x1F382;</span>

        <Link to="/usuarionovo" className={styles.loginParagrafo}>
          Cadastro
        </Link>
      </div>
        </Modal> : null}

        
        
      <div className={`${styles.produtos} animeLeft`}>
        <Head
          title="Cantinho d' Bolos | Produtos"
          description="Esses São nossos produtos."
        />
        <div>
          <div>
            <h1 className={`${styles.title}`}>Tortas</h1>
          </div>
          <Link to="tortas">
            <div className={styles.torta}>
              <img src={fotoTorta} alt="" />
            </div>
          </Link>
        </div>
        <div>
          <div>
            <h1 className={styles.title}>Bolos</h1>
          </div>
          <Link to="bolos">
            <div className={styles.bolos}>
              <img src={fotoBolos} alt="" />
            </div>
          </Link>
        </div>
        <div>
          <div>
            <h1 className={styles.title}>Rocomboles</h1>
          </div>
          <Link to="/rocomboles">
            <div className={styles.rocombole}>
              <img src={fotoRocombole} alt="" />
            </div>
          </Link>
        </div>

        <div>
          <div>
            <h1 className={styles.title}>Pães </h1>
          </div>
          <Link to="paesitaliano">
            <div className={styles.paes}>
              <img src={fotoPaes} alt="" />
            </div>
          </Link>
        </div>

        <div>
          <div>
            <h1 className={styles.title}>Outros</h1>
          </div>
          <Link to="outros">
            <div className={styles.outros}>
              <img src={fotoOutros} alt="" />
            </div>
          </Link>
        </div>
      </div>
      
    </>
  );
};

export default ProdutosHome;
