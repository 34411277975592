import React, { useContext, useEffect } from 'react';
import styles from './UsuarioNovo.module.css';
import firebase from '../../Utils/Firebase';
import 'firebase/auth';
import 'firebase/firestore';
import Input from '../forms/Input';
import Button from '../forms/Button';
import Head from '../Head';
import imgCadastro from '../../Assets/formSecurity.svg';
import { CartContext } from '../../CartContext';
import MaskedImput from '../forms/MaskedImput';
// import { validate } from 'gerador-validador-cpf';
import Aviso from '../forms/Aviso';

const UsuarioNovo = () => {
  const [username, setUsername] = React.useState('');
  const [senha, setSenha] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [tel, setTel] = React.useState('');
  const [cep, setCep] = React.useState('');
  // const { cpf, setCpf } = useContext(CartContext);
  const [rua, setRua] = React.useState('');
  const [numero, setNumero] = React.useState('');
  const [complemento, setComplemento] = React.useState('');
  const [bairro, setBairro] = React.useState('');
  const [pontos] = React.useState(0);
  const [msgTipo, setMsgTipo] = React.useState('');
  const [msg, setMsg] = React.useState('');
  const [carregando, setCarregando] = React.useState(0);

  const { cepp, setCepp, cepResultado } = useContext(CartContext);

  // const cpfValido = validate(cpf);

  const db = firebase.firestore();

  function cadastrar() {
    setCarregando(1);
    setMsgTipo(null);

    if (!username || !senha) {
      setMsgTipo('erro');
      setMsg('Você precisa informar o email e senha para fazer o cadastro!');
      setCarregando(0);
      setTimeout(() => {
        setMsg(null);
        setMsgTipo(null);
      }, 3000);
      return;
    }
    // if (cpfValido === false) {
    //   setMsgTipo('erro');
    //   setMsg('CPF inválido!');
    //   setCarregando(0);
    //   setTimeout(() => {
    //     setMsg(null);
    //     setMsgTipo(null);
    //   }, 3000);
    //   return;
    // }

    if (cepp.length !== 8) {
      setMsgTipo('erro');
      setMsg('CEP inválido!');
      setCarregando(0);
      setTimeout(() => {
        setMsg(null);
        setMsgTipo(null);
      }, 3000);
      return;
    }

    if (cepResultado.erro === true) {
      setMsgTipo('erro');
      setMsg('CEP inválido!');
      setCarregando(0);
      setTimeout(() => {
        setMsg(null);
        setMsgTipo(null);
      }, 3000);
      return;
    }
    if (!nome || !tel || !cep || !numero) {
      setMsgTipo('erro');
      setMsg('Os campos acmima devem ser preenchidos!');
      setCarregando(0);
      setTimeout(() => {
        setMsg(null);
        setMsgTipo(null);
      }, 3000);
      return;
    }
    firebase
      .auth()
      .createUserWithEmailAndPassword(username, senha)
      .then((r) => {
        db.collection('cadastro')
          .add({
            nome: nome,
            tel: tel,
            cep: cep,
            // cpf: cpf,
            rua: rua,
            numero: numero,
            complemento: complemento,
            bairro: bairro,
            usuario: username,
            pontos: pontos,
          })
          .then(() => {
            setCarregando(0);
            setMsgTipo('sucesso');
            setMsg('Wow! Usuário cadastrado com sucesso!');
            setTimeout(() => {
              window.location.href = '/login';
            }, 2000);
          });
      })
      .catch((erro) => {
        setMsgTipo('erro');
        setCarregando(0);

        switch (erro.message) {
          case 'Password should be at least 6 characters':
            setMsg('A senha deve ter pelo menos 6 caracteres!');
            setCarregando(0);
            setTimeout(() => {
              setMsg(null);
              setMsgTipo(null);
            }, 3000);
            break;
          case 'The email address is already in use by another account.':
            setMsg('Este email já está sendo ultilizado por outro usuário!');
            setCarregando(0);
            setTimeout(() => {
              setMsg(null);
              setMsgTipo(null);
            }, 3000);
            break;
          case 'The email address is badly formatted.':
            setMsg('O formato do seu email é inválido!');
            setCarregando(0);
            setTimeout(() => {
              setMsg(null);
              setMsgTipo(null);
            }, 3000);
            break;

          default:
            setMsg('Não foi possível cadastrar. Tente novamente mais tarde!');
            setCarregando(0);
            setTimeout(() => {
              setMsg(null);
              setMsgTipo(null);
            }, 3000);
            break;
        }
      });
  }

  function handleChange({ target }) {
    setCepp(target.value);
  }

  useEffect(() => {
    setBairro(cepResultado.bairro);
    setRua(cepResultado.logradouro);
    setCep(cepResultado.cep);
  }, [cepResultado]);

  useEffect(() => {
    if (cepResultado.erro) {
      setMsgTipo('erro');
      setMsg('CEP inválido!');
      setCarregando(0);
      setTimeout(() => {
        setMsg(null);
        setMsgTipo(null);
      }, 3000);
    }
    return;
  }, [cepResultado]);

  return (
    <>
      <Head
        title="Cantinho d' Bolos | Cadastro"
        description="Esses São nossos produtos."
      />
      <div className="animeLeft">
        <div>
          <img
            className={styles.imgForm}
            src={imgCadastro}
            alt="imagem de um formulario e, desenho"
          />
        </div>
        <form className={styles.form}>
          <div className={styles.logTitulo}>
            <h1>Cadastro</h1>
          </div>

          <Input
            name="username"
            id={username}
            type="email"
            onChange={(e) => setUsername(e.target.value)}
            label="Email: *"
          />

          <Input
            name="senha"
            id={senha}
            type="password"
            onChange={(e) => setSenha(e.target.value)}
            label="Senha: *"
          />

          <Input
            name="nome"
            id={nome}
            type="text"
            onChange={(e) => setNome(e.target.value)}
            label="Nome: *"
          />

          {/* <label className={styles.cpf} htmlFor="cpf">
            CPF:
          </label>
          <MaskedImput
            id="cpf"
            value={cpf}
            mask="999.999.999-99"
            onChange={({ target }) => setCpf(target.value)}
            placeholder="Para programa de pontos."
          /> */}

          <label className={styles.cpf} htmlFor="tel">
            Tel: *
          </label>
          <MaskedImput
            id="tel"
            value={tel}
            mask="(99)99999-9999"
            onChange={({ target }) => setTel(target.value)}
          />
          <label className={styles.cpf} htmlFor="cep">
            CEP: *
          </label>
          <MaskedImput
            id="cep"
            value={cepp}
            mask="99999-999"
            onChange={handleChange}
          />
          <Input
            name="rua"
            id={rua}
            type="text"
            label="Rua: *"
            value={cepResultado && cepResultado.logradouro}
            placeholder="Preencha o campo CEP"
          />

          <Input
            name="numero"
            id={numero}
            type="text"
            onChange={(e) => setNumero(e.target.value)}
            label="Número: *"
          />
          <Input
            name="complemento"
            id={complemento}
            type="text"
            onChange={(e) => setComplemento(e.target.value)}
            label="Complemento:"
          />
          <Input
            name="bairro"
            id={bairro}
            type="text"
            label="Bairro: *"
            value={cepResultado && cepResultado.bairro}
            placeholder="Preencha o campo CEP"
          />

          {carregando ? (
            <Button disabled>Carregando...</Button>
          ) : (
            <Button type="button" onClick={cadastrar}>
              Cadastrar
            </Button>
          )}
        </form>

        {msgTipo === 'sucesso' && <Aviso texto={msg} />}
        {msgTipo === 'erro' && <Aviso texto={msg} />}
      </div>
    </>
  );
};

export default UsuarioNovo;
