import React, { useContext, useEffect } from 'react';
import styles from './Sidebar.module.css';
import Dock from 'react-dock';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CartContext } from '../../CartContext';
import wapp from '../../Assets/whatsapp.svg';
import login from '../../Assets/Login.svg';
import Button from '../../Components/forms/Button';
import Frete from '../frete/Frete';
import delivery from '../../Assets/delivery.png';
import Aviso from '../forms/Aviso';
import Modal from '../forms/Modal';
import Fim from '../../Assets/finish.svg';
import money from '../../Assets/money.svg';

import useFetchCadastro from '../cadastro/useFetchCadastro';

const Sidebar = () => {
  const { cart, removeFromCart, cadastroId, frete, msgFrete } =
    useContext(CartContext);

  const [opened, setOpened] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [totalAdd, setTotalAdd] = React.useState(0);
  const [cartNome, setCartNome] = React.useState([]);
  const [pagamento, setPagamento] = React.useState('');
  const [entrega, setEntrega] = React.useState('');
  const [horario, setHorario] = React.useState('');
  const [data, setData] = React.useState('');
  const [hora, setHora] = React.useState();
  const [min, setMin] = React.useState();
  const [dayDate, setDayDate] = React.useState();
  const [monthData, setMonthData] = React.useState();
  const [yearData, setYearData] = React.useState();
  const [aviso, setAviso] = React.useState('');
  const [msgTipo, setMsgTipo] = React.useState('');
  const [modalVisible, setModalVilible] = React.useState(false);
  const [valorFrete, setValorFrete] = React.useState(0);
  


  const { cadastro } = useFetchCadastro();
  const {} = Frete();

  React.useEffect(() => {
    cart.map((nome) => {
      setCartNome([...cartNome, nome.nome, 'R$' + nome.price + '---']);
    });
  }, [cart]);

  React.useEffect(() => {
    setTotal(
      cart.map(({ price }) => parseInt(price)).reduce((a, b) => a + b, 0),
    );
  }, [cart]);

  React.useEffect(() => {
    window.addEventListener('openCart', () => {
      setOpened(true);
    });
  }, []);

  const removeFromCartNome = cartNome.filter((item) => {
    return item !== item;
  });

  

  function removeCart(produto) {
    removeFromCart(produto);
    setCartNome(removeFromCartNome);
  }

  React.useEffect(() => {
    console.log(cadastroId);
  }, [cadastro]);

  function handleChange({ target }) {
    setPagamento(target.value);
  }
  function handleChangeEntrega({ target }) {
    setEntrega(target.value);
  }



  function handleClick() {
    if (cart.length === 0) {
      setMsgTipo('erro');
      setAviso('Adicione algum item antes de enviar seu pedido!');
      setTimeout(() => {
        setMsgTipo(null);
      }, 3000);
    } else if (entrega === '') {
      setMsgTipo('erro');
      setAviso(
        'Os campos: Entrega, Pagamento, Data e Horário, devem ser preenchidos',
      );
      setTimeout(() => {
        setMsgTipo(null);
      }, 3000);
    } else if (pagamento === '') {
      setMsgTipo('erro');
      setAviso(
        'Os campos: Entrega, Pagamento, Data e Horário, devem ser preenchidos',
      );
      setTimeout(() => {
        setMsgTipo(null);
      }, 3000);
    } else if (horario === '') {
      setMsgTipo('erro');
      setAviso(
        'Os campos: Entrega, Pagamento, Data e Horário, devem ser preenchidos',
      );
      setTimeout(() => {
        setMsgTipo(null);
      }, 3000);
    } else if (data === '') {
      setMsgTipo('erro');
      setAviso(
        'Os campos: Entrega, Pagamento, Data e Horário, devem ser preenchidos',
      );
      setTimeout(() => {
        setMsgTipo(null);
      }, 3000);
    } else {
      setMsgTipo('ok');
      setModalVilible(true);
      setAviso(
        'Legal..., Agora, envie seu pedido pelo whatsapp e espere a confirmação em alguns instantes!!',
      );
    }
  }

  function handleClangePix({ target }) {
    setMsgTipo('pix')
    setPagamento(target.value);
    setModalVilible(true);
    setAviso(
      ' Somente faça o pagamento, depois que enviar e ter a confirmação do pedido.',
    );
    return
  }

  function handleClose() {
    setModalVilible(false);
    window.open(
      `${`https://api.whatsapp.com/send?phone=5579999779613&text= Tipo: ${
        entrega === 'Delivery' ? 'Delivery' : 'Retirar na loja'
      } %0A Nome: ${cadastroId ? cadastroId.nome : ''} %0A Tel: ${
        cadastroId ? cadastroId.tel : ''
      } %0A Rua: ${cadastroId ? cadastroId.rua : ''} N: ${
        cadastroId ? cadastroId.numero : ''
      }  %0A Bairro: ${cadastroId ? cadastroId.bairro : ''} %0A Complemento: ${
        cadastroId ? cadastroId.complemento : ''
      } %0A  (MEU PEDIDO) %0A  ${cartNome} %0A %0A Pagamento: ${pagamento}%0A Data: ${data}%0A Hora: ${horario}%0A Frete: R$ ${
        frete ? valorFrete.toFixed(2).replace('.', ',') : msgFrete
      } %0A Total R$ ${
        entrega === 'Delivery'
          ? totalAdd.toFixed(2).replace('.', ',')
          : total.toFixed(2).replace('.', ',')
      } `}`,
    );
  }

  React.useEffect(() => {
    let listaTorta = []; 
    cart.map((produto) => {
      if(produto.tipo.includes('Tortas')){
        listaTorta.push([
          produto.tipo
        ])
      }

      if(listaTorta.length > 0) {
        setValorFrete(frete + 5)
      }else {
        setValorFrete(frete)
      }
    })
  }, [cart])
  

  React.useEffect(() => {
    setTotalAdd(total + valorFrete);
  }, [total]);

  useEffect(() => {
    const data = new Date();
    const horaNow = data.getHours();
    const min = data.getMinutes();
    const dayDate = data.getDate();
    const mDate = data.getMonth() + 1;
    const year = data.getFullYear();

    
    setYearData(year);
    setHora(horaNow + 1);
    if (min < 10) {
      setMin('0' + min);
    } else {
      setMin(min);
    }
    
    if (dayDate < 10) {
      setDayDate('0' + dayDate);
    } else {
      setDayDate(dayDate);
    }
    
    if (mDate < 10) {
      setMonthData('0' + mDate);
    } else {
      setMonthData(mDate);
    }
  }, [data]);
  
  console.log(Number(data.slice(8)) === Number(dayDate) )
  
  
  return (
    <Dock
      isVisible={opened}
      onVisibleChange={(visible) => {
        setOpened(visible);
      }}
      position="right"
      size={0.8}
    >
      <div className={styles.dock}>
        {useSelector((state) => state.usuarioLogado) > 0 ? (
          <div className={styles.title}>
            <h1 className={styles.titleH1}>Meu Pedido ({cart.length})</h1>
          </div>
        ) : null}

        <div className={styles.dockContent}>
          {useSelector((state) => state.usuarioLogado) > 0 ? (
            <>
              <div>
                <div className={styles.produtos}>
                  {cart.map((r, index) => (
                    <div key={index}>
                      <div className={styles.titulo}>
                        <img
                          className={styles.img}
                          src={r.fotoSidebar}
                          alt=""
                        />
                        <h3 className={styles.nome}>{r.nome}</h3>
                      </div>
                      <div className={styles.priceRemove}>
                        <p> R$ {r.price}</p>
                        <button
                          className={styles.removeBtn}
                          onClick={() => removeCart(r)}
                        >
                          remover
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                {msgTipo === 'ok' && modalVisible ? (
                  <Modal onClose={handleClose}>
                    <div>
                      <img className={styles.imgFim} src={Fim} alt="" />
                    </div>
                    <p>{aviso}</p>
                  </Modal>
                ) : null}
                {msgTipo === 'erro' && <Aviso texto={aviso} />}

                {msgTipo === 'pix' && modalVisible ? (
                  <Modal onClose={() => setModalVilible(false)}>
                    <div>
                      <img className={styles.imgFim} src={money} alt="transferencia" />
                    </div>
                    <h3>Pix: 20627459000127</h3>
                    <p>{aviso}</p>
                  </Modal>
                ) : null}

                <div className={styles.footer}>
                  <div className={styles.entrega}>
                    <label className={styles.labelEntrega}>
                      <input
                        type="radio"
                        name="entrega"
                        value="Delivery"
                        onChange={handleChangeEntrega}
                      />
                      Delivery
                    </label>
                    <label className={styles.labelEntrega}>
                      <input
                        type="radio"
                        name="entrega"
                        value="Loja"
                        onChange={handleChangeEntrega}
                      />
                      Loja
                    </label>
                  </div>

                  <div className={styles.pagamento}>
                    <label className={styles.inputRadio}>
                      <input
                        type="radio"
                        value="Dinheiro"
                        name="pagamento"
                        onChange={handleChange}
                      />{' '}
                      Dinheiro
                    </label>
                    <label className={styles.inputRadio}>
                      <input
                        type="radio"
                        required
                        value="Cartão"
                        name="pagamento"
                        onChange={handleChange}
                      />{' '}
                      Cartão
                    </label>
                    <label className={styles.inputRadio}>
                      <input
                        type="radio"
                        value="Pix"
                        name="pagamento"
                        onChange={handleClangePix}
                      />{' '}
                      Pix
                    </label>
                  </div>

                  <div className={styles.diaEhora}>
                    <input
                      className={styles.inputData}
                      type="date"
                      name="horario"
                      min={`${yearData}-${monthData}-${dayDate}`}
                      onChange={({ target }) => setData(target.value)}
                    />

                    <select
                      className={styles.styleHorario}
                      name="horario"
                      id={horario}
                      value={horario}
                      onChange={({ target }) => setHorario(target.value)}
                    >
                      <option disabled value="">
                        Horário
                      </option>


                      {Number(data.slice(8)) === Number(dayDate) ? (
                        <>
                          {hora >= '11' ? (
                            <option
                              disabled
                              value={`10:${min}`}
                            >{`10:${min}`}</option>
                          ) : (
                            <option value={`10:${min}`}>{`10:${min}`}</option>
                          )}

                          {hora >= '12' ? (
                            <option
                              disabled
                              value={`11:${min}`}
                            >{`11:${min}`}</option>
                          ) : (
                            <option value={`11:${min}`}>{`11:${min}`}</option>
                          )}

                          {hora >= '13' ? (
                            <option
                              disabled
                              value={`12:${min}`}
                            >{`12:${min}`}</option>
                          ) : (
                            <option value={`12:${min}`}>{`12:${min}`}</option>
                          )}

                          {hora >= '14' ? (
                            <option
                              disabled
                              value={`13:${min}`}
                            >{`13:${min}`}</option>
                          ) : (
                            <option value={`13:${min}`}>{`13:${min}`}</option>
                          )}

                          {hora >= '15' ? (
                            <option
                              disabled
                              value={`14:${min}`}
                            >{`14:${min}`}</option>
                          ) : (
                            <option value={`14:${min}`}>{`14:${min}`}</option>
                          )}

                          {hora >= '16' ? (
                            <option
                              disabled
                              value={`15:${min}`}
                            >{`15:${min}`}</option>
                          ) : (
                            <option value={`15:${min}`}>{`15:${min}`}</option>
                          )}

                          {hora >= '17' ? (
                            <option
                              disabled
                              value={`16:${min}`}
                            >{`16:${min}`}</option>
                          ) : (
                            <option value={`16:${min}`}>{`16:${min}`}</option>
                          )}

                          {hora >= '18' ? (
                            <option
                              disabled
                              value={`17:${min}`}
                            >{`17:${min}`}</option>
                          ) : (
                            <option value={`17:${min}`}>{`17:${min}`}</option>
                          )}
                        </>
                      ) : (
                        <>
                          {hora >= '11' ? (
                            <option value={`10:${min}`}>{`10:${min}`}</option>
                          ) : (
                            <option value={`10:${min}`}>{`10:${min}`}</option>
                          )}

                          {hora >= '12' ? (
                            <option value={`11:${min}`}>{`11:${min}`}</option>
                          ) : (
                            <option value={`11:${min}`}>{`11:${min}`}</option>
                          )}

                          {hora >= '13' ? (
                            <option value={`12:${min}`}>{`12:${min}`}</option>
                          ) : (
                            <option value={`12:${min}`}>{`12:${min}`}</option>
                          )}

                          {hora >= '14' ? (
                            <option value={`13:${min}`}>{`13:${min}`}</option>
                          ) : (
                            <option value={`13:${min}`}>{`13:${min}`}</option>
                          )}

                          {hora >= '15' ? (
                            <option value={`14:${min}`}>{`14:${min}`}</option>
                          ) : (
                            <option value={`14:${min}`}>{`14:${min}`}</option>
                          )}

                          {hora >= '16' ? (
                            <option value={`15:${min}`}>{`15:${min}`}</option>
                          ) : (
                            <option value={`15:${min}`}>{`15:${min}`}</option>
                          )}

                          {hora >= '17' ? (
                            <option value={`16:${min}`}>{`16:${min}`}</option>
                          ) : (
                            <option value={`16:${min}`}>{`16:${min}`}</option>
                          )}

                          {hora >= '18' ? (
                            <option value={`17:${min}`}>{`17:${min}`}</option>
                          ) : (
                            <option value={`17:${min}`}>{`17:${min}`}</option>
                          )}
                        </>
                      )}

                      {hora >= 18 && <option>Fechado</option>}
                    </select>
                  </div>

                  {entrega === 'Delivery' ? (
                    <div className={styles.freteActive}>
                      <img
                        className={styles.imgDelivery}
                        src={delivery}
                        alt="carrinho de entrega"
                      />
                      <h5>
                        {' '}
                        {frete ? valorFrete.toFixed(2).replace('.', ',') : msgFrete}
                      </h5>
                    </div>
                  ) : (
                    <div className={styles.frete}>
                      <img
                        className={styles.imgDelivery}
                        src={delivery}
                        alt="carrinho de entrega"
                      />
                      <h5>
                        {' '}
                        {frete ? valorFrete.toFixed(2).replace('.', ',') : msgFrete}
                      </h5>
                    </div>
                  )}

                  <div className={styles.total}>
                    <p>Total:</p>
                    <h1>
                      R${' '}
                      {entrega === 'Delivery'
                        ? totalAdd.toFixed(2).replace('.', ',')
                        : total.toFixed(2).replace('.', ',')}
                    </h1>
                  </div>

                  <button className={styles.btnTotal} onClick={handleClick}>
                    <img
                      className={styles.logoWapp}
                      src={wapp}
                      alt="logo WhatsApp"
                    />
                    Enviar Para WhatssApp
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <Link to="login">
                <div className={styles.fazerCadastro}>
                  <img
                    className={styles.imgLogin}
                    src={login}
                    alt="Desenho de um homem abrindo a porta"
                  />
                  <h1>Faça seu login ou cadastro e nos envie seu pedido!</h1>
                  <div className={styles.btnCadastro}>
                    <Button
                      onClick={() => {
                        window.location.href = '/usuarionovo';
                      }}
                    >
                      Cadastrar
                    </Button>
                  </div>
                  <div className={styles.btnCadastro}>
                    <Button
                      onClick={() => {
                        window.location.href = '/login';
                      }}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </Link>
            </>
          )}
        </div>
      </div>
    </Dock>
  );
};

export default Sidebar;
