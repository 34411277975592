import React from 'react';
import styles from './Funcionamento.module.css';

const Funcionamento = () => {
  const [diasSemana, ] = React.useState([1, 2, 3, 4, 5]);
  const [diasSabado, ] = React.useState([6]);
  const [horarioSemana, ] = React.useState([9, 18]);
  const [horarioSabado, ] = React.useState([9, 16]);
  const [aberto, SetAberto] = React.useState(false);
  const [fechado, SetFechado] = React.useState(false);

  const dataAgora = new Date();
  const diaAgora = dataAgora.getDay();
  const horarioAgora = dataAgora.getHours();

  const semanaAberto = diasSemana.indexOf(diaAgora) !== -1;
  const semanaSabado = diasSabado.indexOf(diaAgora) !== -1;

  
  const sabadoAberto = horarioAgora >= horarioSabado[0] && horarioAgora < horarioSabado[1];

  
  const horarioAberto =
    horarioAgora >= horarioSemana[0] && horarioAgora < horarioSemana[1];

  React.useEffect(() => {
    if (semanaAberto && horarioAberto) {
      SetAberto(true);
    } else if ( semanaSabado && sabadoAberto) {
      SetAberto(true);
    }else {
      SetFechado(true)
    }
  });


     if(aberto) return <div className={styles.funcionamento}>Aberto</div>
     if(fechado)return <div className={styles.fechado}>Fechado</div>
  return (
    <>
    {aberto ? aberto : fechado }
    </>
  );
};

export default Funcionamento;
