import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import './App.css';
import Contato from './Components/contato/Contato';
import ProdutosHome from './Components/produtos/ProdutosHome';
import Login from './Components/login/Login';
import UsuarioNovo from './Components/login/UsuarioNovo';
import RecuperarSenha from './Components/login/RecuperarSenha';
import NavbarLogin from './Components/navbar/NavbarLogin';
import { store, persistor } from './store/';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import PublicarProduto from './Components/produtos/PublicarProduto';
import Tortas from './Components/produtos/Tortas';
import Bolos from './Components/produtos/Bolos';
import Rocombole from './Components/produtos/Rocombole';
import PaesItalianos from './Components/produtos/PaesItalianos';
import Outros from './Components/produtos/Outros';
import ProdutoDetalhes from './Components/produtos/ProdutoDetalhes';
import BtnWhatsapp from './Components/BtnWhatsapp';
import Funcionamento from './Components/funcionamento/Funcionamento';
import Estatisticas from './Components/estatisticas/Estatisticas';
import Sidebar from './Components/sidebar/Sidebar';
import { CartProvider } from './CartContext';
import Pontos from './Components/pontos/Pontos';
import Perfil from './Components/perfil/Perfil';
import Regras from './Components/regras/Regras';

const App = () => {
  return (
    <CartProvider>
      <div className="App">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Header />
              <Route path="/sidebar" element={<Sidebar />} />
              <div className="content">
                <Routes>
                  <Route exact path="/" end element={<ProdutosHome />} />
                  <Route path="/login/" element={<Login />} />
                  <Route path="/usuarionovo" element={<UsuarioNovo />} />
                  <Route path="/recuperar" element={<RecuperarSenha />} />
                  <Route path="/contato" element={<Contato />} />
                  <Route path="/funcionamento" element={<Funcionamento />} />
                  <Route path="/pontos" element={<Pontos />} />
                  <Route path="/regras" element={<Regras />} />

                  <Route path="/perfil" element={<Perfil />} />
                  <Route
                    path="/produtodetalhes/:id/*"
                    element={<ProdutoDetalhes />}
                  />
                  <Route
                    path="/editarproduto/:id"
                    element={<PublicarProduto />}
                  />
                  <div className="produto">
                    <Route path="/outros" element={<Outros />} />
                  </div>
                  <div className="produto">
                    <Route path="/paesitaliano" element={<PaesItalianos />} />
                  </div>
                  <div className="produto">
                    <Route path="/rocomboles" element={<Rocombole />} />
                  </div>
                  <div className="produto">
                    <Route path="/tortas" element={<Tortas />} />
                  </div>
                  <div className="produto">
                    <Route path="/bolos" element={<Bolos />} />
                  </div>
                  <Route path="/btn" element={<BtnWhatsapp />} />
                  <Route
                    path="/publicarproduto"
                    element={<PublicarProduto />}
                  />
                  <div>
                    <Route path="/estatisticas" element={<Estatisticas />} />
                  </div>
                </Routes>
              </div>
              <Route path="/navbar" element={<NavbarLogin />} />
              <Footer />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </div>
    </CartProvider>
  );
};

export default App;
