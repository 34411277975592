import React from 'react'
import styles from './Footer.module.css'



const Footer = () => {
  return (
    <div className={styles.footer}>
      <p>Desenvolvido por Ednardo Paz</p>
    </div>
  )
}

export default Footer
