import React from 'react'
import styles from './BtnWhatsapp.module.css'
import logo from '../Assets/whatsapp.svg'

const BtnWhatsapp = (href) => {



  return (
    <a  href="https://api.whatsapp.com/send?phone=5579999779613&text=Texto%20aqui" target="_blank"  className={styles.btnWhatsapp} >
     <img className={styles.logo} src={logo} alt="" />
    </a>
    )
}

export default BtnWhatsapp
