import React from 'react';
import styles from './Card.module.css';
import firebase from '../../Utils/Firebase';
import 'firebase/firestore';
import 'firebase/storage';
import { Link } from 'react-router-dom';
import Image from '../skeleton/Image';

export const Card = ({ nome, id, img }) => {
  const [urlImagem, setUrlImagem] = React.useState();

  React.useEffect(() => {
    firebase
      .storage()
      .ref(`img/${img}`)
      .getDownloadURL()
      .then((url) => setUrlImagem(url));
  }, [urlImagem]);

  return (
    <>
      <div className={`animeLeft ${styles.produto}`}>
        
            <div>
            <h1 className={styles.title}>{nome}</h1>
            </div>
          <Link id={id} to={`/produtodetalhes/${id}`}>
            <div>
            <Image src={urlImagem} alt="foto" />+ Detalhes
            </div>
          </Link>
        
      </div>
    </>
  );
};

export default Card;
