import React from 'react';
import styles from './Login.module.css';
import Input from '../forms/Input';
import Button from '../forms/Button';
import firebase from '../../Utils/Firebase';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import Head from '../Head';

import { useSelector, useDispatch } from 'react-redux';

const Login = () => {
  const [username, setUsername] = React.useState('');
  const [senha, setSenha] = React.useState('');
  const [msgTipo, setMsgTipo] = React.useState('');
  const [carregando, setCarregando] = React.useState();

  const dispatch = useDispatch();

  function logar() {
    setCarregando(1);
    firebase
      .auth()
      .signInWithEmailAndPassword(username, senha)
      .then((r) => {
        setCarregando(0);
        setMsgTipo('SUCESSO');
        setTimeout(() => {
          dispatch({ type: 'LOG_IN', usuarioEmail: username });
        }, 2000);
      })
      .catch((erro) => {
        setCarregando(0);
        setMsgTipo('erro');
      });
  }

  return (
    <form className={`${styles.form} animeLeft`}>
      <Head
        title="Cantinho d' Bolos | Login"
        description="Esses São nossos produtos."
      />
      <div className={styles.logTitulo}>
        <h1>Login</h1>
      </div>

      {useSelector((state) => state.usuarioLogado) > 0
        ? (window.location.href = '/')
        : null}

      <Input
        id={username}
        onChange={(e) => setUsername(e.target.value)}
        label="Usuário"
        name="username"
        type="text"
      />
      <Input
        id={senha}
        onChange={(e) => setSenha(e.target.value)}
        label="Senha"
        name="password"
        type="password"
      />
      {carregando ? (
        <Button disabled>carregando...</Button>
      ) : (
        <Button onClick={logar} type="button">
          {' '}
          Entrar{' '}
        </Button>
      )}

      {msgTipo === 'SUCESSO' || msgTipo === 'erro' ? (
        <div className={styles.msgLogin}>
          {msgTipo === 'SUCESSO' && (
            <span>
              <strong>Wow! Você está conectado.</strong>
            </span>
          )}

          {msgTipo === 'erro' && (
            <span>
              <strong>
                Ops! Verifique se a senha ou o usuário estão corretos.
              </strong>
            </span>
          )}
        </div>
      ) : (
        <div className={styles.msgLogin}></div>
      )}

      <div className={styles.opcaoLogin}>
        <a href="/recuperar" className={styles.loginParagrafo}>
          Recuperar Senha
        </a>
        <span>&#x1F382;</span>

        <Link to="/usuarionovo" className={styles.loginParagrafo}>
          Fazer Cadastro
        </Link>
      </div>
    </form>
  );
};

export default Login;
