import React, { useContext } from 'react'
import styles from './Header.module.css'
import { NavLink } from 'react-router-dom'
import logo from '../Assets/logocantinho.png'
import carti from '../Assets/cart.png'
import { CartContext } from '../CartContext'


const Header = () => {

  const { cart } = useContext(CartContext)
  // console.log(cart)
 
  const openDrower = () => {
    const event = new CustomEvent('openCart');
    window.dispatchEvent(event);
  }

  return (
    <nav className={styles.header}>
      <ul >
        <li>
          <NavLink  to="/"><img className={`${styles.logo} ${styles.button}`} src={logo} alt=""  /></NavLink>
        </li>
        <li>
          <NavLink activeClassName={styles.active} className={styles.link} to="/" end>Produtos</NavLink>
        </li>
        <li>
          <NavLink activeClassName={styles.active} className={styles.link} to="contato">Contato</NavLink>
        </li>
        <li>
          <NavLink onClick={() => openDrower()} activeClassName={` ${styles.cart}` }className={styles.link} to=""><img src={carti} alt="Carrrinho de comparas" /></NavLink>
        </li>
          {cart.length}
      </ul>
    </nav>
  )
}

export default Header
