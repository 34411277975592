import React, {  useContext } from 'react'
import firebase from '../../Utils/Firebase';
import 'firebase/firestore';
import { useSelector } from 'react-redux';
import { CartContext } from '../../CartContext';


const useFetchCadastro = () => {
  const { cadastro, setCadastro} = useContext(CartContext);
  const usuarioEmail = useSelector((state) => state.usuarioEmail);


  
  
  let listaCadastro = [];
  
  React.useEffect(() => {
    
    

    firebase
    .firestore()
      .collection('cadastro')
      .where('usuario', '==', usuarioEmail)
      .get()
      .then( async(snap) => {
        await snap.docs.forEach((doc) => {
          if(doc.data()) {
            listaCadastro.push({
              id: doc.id,
              ...doc.data()
            })
          }
        })
        setCadastro(listaCadastro)
      })
    },[])

    

  return {
    cadastro
  }
}

export default useFetchCadastro
