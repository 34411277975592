import React, { useContext } from 'react';
import styles from './Perfil.module.css';
import { CartContext } from '../../CartContext';
import imgProf from '../../Assets/Profile.svg';

const Perfil = () => {
  const { cadastroId } = useContext(CartContext);



  return (
    <div className={`${styles.perfil} animeLeft`}>
      <div className={styles.logTitulo}>
        <h1>Perfil</h1>
      </div>
      <div className={styles.imgProf}>
        <img src={imgProf} alt="cartao de perfil" />
      </div>
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>Dados</legend>
        <div className={styles.dados}>
          <div className={styles.nome}>
            <p>Nome: {cadastroId && cadastroId.nome}</p>
          </div>
          <div>
            <p>
              End:{' '}
              {`${cadastroId && cadastroId.rua}, N: ${
                cadastroId && cadastroId.numero
              }`}{' '}
            </p>
          </div>
          <div>
            <p>Bairro: {cadastroId && cadastroId.bairro}</p>
          </div>
          <div>
            <p>Tel: {cadastroId && cadastroId.tel}</p>
          </div>
          <div>
            <p>Pontos: {cadastroId && cadastroId.pontos}</p>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Perfil;
