import React, { useEffect } from 'react';
import styles from './Pontos.module.css';
import firebase from '../../Utils/Firebase';
import 'firebase/firestore';
import 'firebase/auth';
import Input from '../forms/Input';
import Button from '../forms/Button';
import Aviso from '../forms/Aviso';
import Modal from '../forms/Modal';
import { useSelector } from 'react-redux';


let listaNomeCpf = [];

const Pontos = () => {
  const [cpfValor, setCpfValor] = React.useState('');
  const [dados, setDados] = React.useState('');
  const [info, setInfo] = React.useState('');
  const [carregando, setCarregando] = React.useState(0);
  const [pontos, setPontos] = React.useState('');
  const [pontosAdd, setPontosAdd] = React.useState(0);
  const [msgTipo, setMsgTipo] = React.useState(null);
  const [msg, setMsg] = React.useState(null);
  const [modalVisible, setModalVilible] = React.useState(false);

  const usuarioLogado = useSelector((state) => state.usuarioEmail);
  const usuarioGina = 'ginasobraladv@yahoo.com';

  const id = info.id;

  function handleClick() {
    if (!cpfValor || cpfValor.length !== 11) {
      setMsgTipo('erro');
      setMsg('Digite um CPF válido!');
      setTimeout(() => {
        setMsgTipo(null);
        setMsg(null);
      }, 3000);
      return;
    }

    setCarregando(1);
    firebase
      .firestore()
      .collection('cadastro')
      .where('cpf', '==', cpfValor)
      .get()
      .then(async (snap) => {
        await snap.docs.forEach((doc) => {
          if (doc.data()) {
            listaNomeCpf.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });
        setDados(listaNomeCpf);
        setCarregando(0);
        if (listaNomeCpf.length === 0) {
          setMsgTipo('erro');
          setMsg('CPF não encontrado!');
          setTimeout(() => {
            setMsgTipo(null);
            setMsg(null);
          }, 3000);
          return;
        }
      })
      .catch((erro) => {
        console.log(erro);
        setMsgTipo('erro');
        setMsg('CPF não encontrado!');
      });
  }
  // console.log(dados);

  function addPontos() {
    if (id) {
      firebase
        .firestore()
        .collection('cadastro')
        .doc(id)
        .update({
          pontos: pontosAdd,
        })
        .then(() => {
          setMsgTipo('add');
          setMsg('Pontos Adicionados!');
          setTimeout(() => {
            setMsgTipo(null);
            setMsg(null);
            handleClick();
          }, 3000);
        })
        .catch((err) => {
          setMsgTipo('erro');
          setMsg('Erro');
          setTimeout(() => {
            setMsgTipo(null);
            setMsg(null);
          }, 3000);
        });
    }
    if (pontos) {
      setPontosAdd(pontosAdd);
      setPontos('');
    }
  }

  useEffect(() => {
    if (pontos) {
      setPontosAdd(Number(pontos) + Number(info.pontos));
    }
  }, [pontos]);

  useEffect(() => {
    if (dados === null) return null;
    dados &&
      dados.map((item) => {
        setInfo(item);
      });
  });
  function ModalZerarPontos() {
    setMsgTipo('zerar');
    setModalVilible(true);
    setMsg('Está ação irá zerar todos os pontos do usuário, deseja continuar?');
  }
  function handleZerar() {
    setPontosAdd(0);
    addPontos();
    setModalVilible(false);
  }
  console.log(pontosAdd);

  return (
    <>
      {msgTipo === 'erro' && <Aviso texto={msg} />}
      {msgTipo === 'add' && <Aviso texto={msg} />}

      {msgTipo === 'zerar' && modalVisible ? (
        <Modal onClose={() => setModalVilible(false)}>
          <div>
            <p>{msg}</p>
          </div>
          <div className={styles.btnModal}>
            <Button onClick={() => setModalVilible(false)}>Cancelar</Button>
            <Button onClick={handleZerar}>Zerar pontos</Button>
          </div>
        </Modal>
      ) : null}

      <div className={styles.pontos}>
        <form
          className={`${styles.form} animeLeft`}
          onSubmit={(event) => event.preventDefault()}
        >
          <div className={styles.logTitulo}>
            <h1>Programa de Pontos.</h1>
          </div>
          <div className={styles.input}>
            <Input
              label="Digite o CPF:"
              maxLength="11"
              onChange={({ target }) => {
                setCpfValor(target.value);
              }}
            />

            {usuarioLogado === usuarioGina ? carregando ? (
              <Button type="button" disabled onClick={handleClick}>
                Carregando...
              </Button>
            ) : (
              <Button type="button" onClick={handleClick}>
                Pesquisar
              </Button>
            ) : <Button type="button" disabled onClick={handleClick}>
            Carregando...
          </Button>}

            
          </div>

          <div className={styles.input}>
            <label className={styles.labelSelect} htmlFor="adicione">
              Adicione Pontos:
            </label>
            <select
              id="adicione"
              type="number"
              onChange={({ target }) => setPontos(target.value)}
              name="Adicione"
              value={pontos}
              className={styles.select}
            >
              <option value="" disabled>
                Selecione
              </option>
              <option value="1">De R$20,00 à R$ 39,99 = 1 </option>
              <option value="2">De R$40,00 à R$ 59,99 = 2</option>
              <option value="3">De R$60,00 à R$ 79,99 = 3</option>
              <option value="4">De R$80,00 à R$ 99,99 = 4</option>
              <option value="5">De R$100,00 à R$ 119,99 = 5</option>
              <option value="6">De R$120,00 à R$ 139,99 = 6</option>
              <option value="7">De R$140,00 à R$ 159,99 = 7</option>
              <option value="8">De R$160,00 à R$ 179,99 = 8</option>
              <option value="9">De R$180,00 à R$ 199,99 = 9</option>
            </select>

            {pontos === '' ? (
              <Button type="button" disabled onClick={addPontos}>
                Adicionar
              </Button>
            ) : carregando ? (
              <Button type="button" disabled onClick={addPontos}>
                Carregando...
              </Button>
            ) : (
              <Button type="button" onClick={addPontos}>
                Adicionar
              </Button>
            )}
          </div>

          <div>
            <button
              className={styles.btnZerarPontos}
              type="button"
              onClick={ModalZerarPontos}
            >
              Zerar Pontos
            </button>
          </div>
        </form>

        <div className={styles.dados}>
          <h1>Nome: {info && info.nome} </h1>
          <p>Pontos: {info && info.pontos}</p>
        </div>
        <div className={styles.btnLimpar}>
          <Button onClick={() => (window.location.href = '/pontos')}>
            Limpar
          </Button>
        </div>
      </div>
    </>
  );
};

export default Pontos;
