import React, { useEffect } from 'react';
import 'firebase/firestore';
import Card from './Card';
import useFetchProdutos from './useFetchProdutos';
import Head from '../Head';
import BtnWhatsapp from '../BtnWhatsapp';
import Modal from '../forms/Modal';
import imgTorta from '../../Assets/torta.svg'
import styles from './Torta.module.css'

const FetchProdutos = () => {
  const { tortas, carregando } = useFetchProdutos();
  const [modalVisible, setModalVilible] = React.useState(false);
  


  useEffect(() => {
    if(carregando > 0) {
      setModalVilible(true)
    }
  })
  if (tortas === null) return null;
  return (
    <>
      <BtnWhatsapp />
      <Head
        title="Cantinho d' Bolos | Tortas"
        description="Esses São nossos produtos."
      />
      { modalVisible ? (
        <Modal onClose={() => setModalVilible(false)}>
          <img className={styles.tortaImg} src={imgTorta} alt="Desenho de uma torta" />
          <p>Delícia!!...As Tortas devem ser encomendadas com um dia de antecedência!</p>
        </Modal>
      ) : null}

      {tortas.map((item) => (
        <Card
          key={item.id}
          id={item.id}
          img={item.foto}
          nome={item.nome}
          precoG={item.precoG}
          precoM={item.precoM}
          precoP={item.precoP}
          tipo={item.tipo}
          usuario={item.usuario}
          visualizacoes={item.visualizacoes}
          descricao={item.descricao}
        />
      ))}
    </>
  );
};

export default FetchProdutos;
