import React, { useEffect } from 'react';
import styles from './Estatisticas.module.css';
import useFetchProdutos from '../produtos/useFetchProdutos';
import { VictoryPie, VictoryChart, VictoryBar } from 'victory';
import Head from '../Head';

const Estatisticas = () => {
  const [total, setTotal] = React.useState(0);
  const [grafico, setGrafico] = React.useState([]);
  const [graficoTortas, setGraficoTortas] = React.useState([]);
  const [graficoPaes, setGraficoPaes] = React.useState([]);
  const [graficoOutros, setGraficoOutros] = React.useState([]);
  const [graficoRocombole, setGraficoRocombole] = React.useState([]);
  const [graficoTipo, setGraficoTipo] = React.useState([]);
  const { visualizacoes, bolos, tortas, rocombole, paes, outros } =
    useFetchProdutos();

  // console.log(visualizacoes)
  useEffect(() => {
    if (visualizacoes === null) return null;

    const dataGraphBolos = bolos.map((item) => {
      return {
        x: item.nome,
        y: item.visualizacoes,
      };
    });

    const dataGraphOutros = outros.map((item) => {
      return {
        x: item.nome,
        y: item.visualizacoes,
      };
    });

    const dataGraphPaes = paes.map((item) => {
      return {
        x: item.nome,
        y: item.visualizacoes,
      };
    });

    const dataGraphRocombole = rocombole.map((item) => {
      return {
        x: item.nome,
        y: item.visualizacoes,
      };
    });

    const dataGraphTortas = tortas.map((item) => {
      return {
        x: item.nome,
        y: item.visualizacoes,
      };
    });

    const dataGraphTipo = visualizacoes.map((item) => {
      return {
        x: item.tipo,
        y: item.visualizacoes,
      };
    });

    setTotal(
      visualizacoes
        .map(({ visualizacoes }) => visualizacoes)
        .reduce((a, b) => a + b),
    );

    setGraficoTipo(dataGraphTipo);

    setGrafico(dataGraphBolos);
    setGraficoTortas(dataGraphTortas);
    setGraficoRocombole(dataGraphRocombole);
    setGraficoPaes(dataGraphPaes);
    setGraficoOutros(dataGraphOutros);
  }, [visualizacoes]);

  return (
    <>
      <Head
        title="Cantinho d' Bolos | Estatisticas"
        description="Esses São nossos produtos."
      />
      <section className={`animeLeft ${styles.grafico}`}>
        <h1>Estatísticas</h1>
        <div className={`${styles.titulo} ${styles.graficoItem}`}>
          <p>Acessos: {total}</p>
        </div>
        <div className={`${styles.victoryChart} ${styles.graficoItem} `}>
          <VictoryChart>
            <VictoryBar alignment="start" data={graficoTipo}></VictoryBar>
          </VictoryChart>
        </div>
        <div className={`${styles.victoryPie} ${styles.graficoItem}`}>
          <VictoryPie
            data={grafico}
            padding={{ top: 5, bottom: 5, left: 150, right: 150 }}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: '#fff',
                strokeWidth: 1,
              },
              labels: {
                fontSize: 14,
              },
            }}
            innerRadius={20}
          />
        </div>
        <div className={`${styles.victoryPie} ${styles.graficoItem}`}>
          <VictoryPie
            data={graficoTortas}
            padding={{ top: 0, bottom: 0, left: 150, right: 150 }}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: '#fff',
                strokeWidth: 1,
              },
              labels: {
                fontSize: 14,
              },
            }}
            innerRadius={20}
          />

          <VictoryPie
            data={graficoRocombole}
            padding={{ top: 0, bottom: 0, left: 150, right: 150 }}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: '#fff',
                strokeWidth: 1,
              },
              labels: {
                fontSize: 14,
              },
            }}
            innerRadius={20}
          />

          <VictoryPie
            data={graficoPaes}
            padding={{ top: 0, bottom: 0, left: 150, right: 150 }}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: '#fff',
                strokeWidth: 1,
              },
              labels: {
                fontSize: 14,
              },
            }}
            innerRadius={20}
          />

          <VictoryPie
            data={graficoOutros}
            padding={{ top: 0, bottom: 0, left: 150, right: 150 }}
            style={{
              data: {
                fillOpacity: 0.9,
                stroke: '#fff',
                strokeWidth: 1,
              },
              labels: {
                fontSize: 14,
              },
            }}
            innerRadius={20}
          />
        </div>
      </section>
    </>
  );
};

export default Estatisticas;
