import React from 'react';
import firebase from '../../Utils/Firebase';
import 'firebase/firestore';


const useFetchProdutos = () => {
  const [tortas, setTortas] = React.useState(null);
  const [bolos, setBolos] = React.useState(null);
  const [rocombole, setRocombole] = React.useState(null);
  const [paes, setPaes] = React.useState(null);
  const [outros, setOutros] = React.useState(null);
  const [carregando, setCarregando] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [visualizacoes, setVisualizacoes] = React.useState(null);
  

  
  let listaProdutos = [];
  let listaBolos = [];
  let listaRocomboles = [];
  let listaPaes = [];
  let listaOutros = [];
  let listaVisualizacoes= [];

 
  
  React.useEffect(() => {
    try {
      firebase
      .firestore()
      .collection('produtos')
      .get()
      .then(async (snap) => {
        setCarregando(1)
        await snap.docs.forEach((doc) => {
          if (doc.data().tipo.includes('Tortas')) {
            listaProdutos.push({
              id: doc.id,
              ...doc.data(),
            })
          }
          if(doc.data().tipo.includes('Bolos')) {
            listaBolos.push({
              id: doc.id,
              ...doc.data(),
            }  
            )
          }
          if(doc.data().tipo.includes('Rocomboles')) {
            listaRocomboles.push({
              id: doc.id,
              ...doc.data(),
            }  
            )
          }
          if(doc.data().tipo.includes('Pães Italiano')) {
            listaPaes.push({
              id: doc.id,
              ...doc.data(),
            }  
            )
          }
          if(doc.data().tipo.includes('Outros')) {
            listaOutros.push({
              id: doc.id,
              ...doc.data(),
            }  
            )
          }
          if(doc.data().visualizacoes) {
            listaVisualizacoes.push({
              id: doc.id,
              ...doc.data(),
            }  
            )
          }
          
        });
        
        
        setTortas(listaProdutos);
        setBolos(listaBolos);
        setRocombole(listaRocomboles);
        setPaes(listaPaes);
        setOutros(listaOutros);
        setVisualizacoes(listaVisualizacoes);
        setCarregando(false)
      })
    }catch(erro){
      setError('Um erro está ocorrendo!')
    } 
    
  },[]);

  
  return { tortas, bolos, rocombole, paes, outros, visualizacoes, error, carregando };
};

export default useFetchProdutos;
