import React from 'react';
import styles from './Input.module.css';

const Input = ({ label, type, name, ...props }) => {
  return (
    <div className={styles.wrapper}>
      <label className={styles.label} htmlFor={name}>{label}</label>
      <input {...props} id={name} name={name} className={styles.input} type={type} />
    </div>
  );
};

export default Input;
