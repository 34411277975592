import React from 'react';
import { useContext } from 'react';
import { CartContext } from '../../CartContext';

export const Frete = () => {
  const { cadastroId } = useContext(CartContext);
  const { frete, setFrete } = useContext(CartContext);
  const {  setMsgFrete} = useContext(CartContext);


 

  React.useEffect(() => {
    if (cadastroId === null) return null;

    switch (cadastroId.bairro) {
      case 'Salgado Filho' : 
      setFrete(7)
      break
      case 'Luzia' : 
      setFrete(7)
      break
      case 'Treze de Julho' : 
      setFrete(5)
      break
      case '17 de Março' : 
      setFrete(12)
      break
      case 'Aeroporto' : 
      setFrete(13)
      break
      case 'América' : 
      setFrete(10)
      break
      case 'Atalaia' : 
      setFrete(10)
      break
      case 'Bugio' : 
      setFrete(15)
      break
      case 'Capucho' : 
      setFrete(15)
      break
      case 'Centro' : 
      setFrete(7)
      break
      case 'Cidade Nova' : 
      setFrete(13)
      break
      case 'Cirurgia' : 
      setFrete(10)
      break
      case 'Coroa do Meio' : 
      setFrete(10)
      break
      case 'Dezoito do Forte' : 
      setFrete(13)
      break
      case 'Dom Luciano' : 
      setFrete(15)
      break
      case 'Farolândia' : 
      setFrete(10)
      break
      case 'Getúlio Vargas' : 
      setFrete(12)
      break
      case 'Grageru' : 
      setFrete(12)
      break
      case 'Inácio Barbosa' : 
      setFrete(12)
      break
      case 'Industrial' : 
      setFrete(12)
      break
      case 'Jabotiana' : 
      setFrete(12)
      break
      case 'Jardim Centenário' : 
      setFrete(20)
      break
      case 'Jardins' : 
      setFrete(7)
      break
      case 'José Conrado de Araújo' : 
      setFrete(15)
      break
      case 'Lamarão' : 
      setFrete(15)
      break
      case 'Marivan' : 
      setFrete(15)
      break
      case 'Novo Paraíso' : 
      setFrete(15)
      break
      case 'Olaria' : 
      setFrete(15)
      break
      case 'Pereira Lobo' : 
      setFrete(10)
      break
      case 'Ponto Novo' : 
      setFrete(10)
      break
      case 'Porto Dantas' : 
      setFrete(10)
      break
      case 'Santa Maria' : 
      setFrete(10)
      break
      case 'Santo Antônio' : 
      setFrete(12)
      break
      case 'Santos Dumont' : 
      setFrete(15)
      break
      case 'São Conrado' : 
      setFrete(10)
      break
      case 'São José' : 
      setFrete(7)
      break
      case 'Siqueira Campos' : 
      setFrete(10)
      break
      case 'Suíssa' : 
      setFrete(7)
      break
      case 'Zona de Expansão (Mosqueiro)' : 
      setFrete(22)
      break
      case 'Aruana' : 
      setFrete(18)
      break
      default :
      setMsgFrete('Consulte o valor do seu frete!')
    }
  });
  
  
  
  return {
    frete
  }
};
export default Frete;
