import React from 'react'
import styles from './Slide.module.css'

const Slide = ({img, texto}) => {
  return (
    <div >
    <img src={img}  alt=""  />
    <p className={styles.parag}>{texto}</p>
    </div>
  )
}

export default Slide
