import React from 'react';
import styles from './PublicarProduto.module.css';
import Input from '../forms/Input';
import Button from '../forms/Button';
import firebase from '../../Utils/Firebase';
import 'firebase/firestore';
import 'firebase/firebase-storage';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';


const PublicarProduto = () => {


  const usuarioLogado = useSelector((state) => state.usuarioEmail);
  const usuarioGina = 'ginasobraladv@yahoo.com';

  const [msgTipo, setMsgTipo] = React.useState('');
  const [nome, setNome] = React.useState('');
  const [tipo, setTipo] = React.useState('selecione');
  const [precoP, setPrecoP] = React.useState('');
  const [precoM, setPrecoM] = React.useState('');
  const [precoG, setPrecoG] = React.useState('');
  const [descricao, setDescricao] = React.useState('');
  const [fotoAtual, setFotoAtual] = React.useState('');
  const [fotoNova, setFotoNova] = React.useState('');
  const usuarioEmail = useSelector((state) => state.usuarioEmail);
  const [carregando, setCarregando] = React.useState();

  const storage = firebase.storage();
  const db = firebase.firestore();

  const { id } = useParams();
  
  React.useEffect(() => {
    if (id) {
      firebase
      .firestore()
      .collection('produtos')
      .doc(id)
      .get()
      .then((resultado) => {
        setNome(resultado.data().nome);
          setTipo(resultado.data().tipo);
          setPrecoP(resultado.data().precoP);
          setPrecoM(resultado.data().precoM);
          setPrecoG(resultado.data().precoG);
          setDescricao(resultado.data().descricao);
          setFotoAtual(resultado.data().foto);
        });
      }
  }, []);
  
  function atualizar() {
    setMsgTipo(null);
    setCarregando(1);

    if (fotoNova) storage.ref().child(`img`).child(fotoNova.name).put(fotoNova);

    db.collection('produtos')
      .doc(id)
      .update({
        nome: nome,
        tipo: tipo,
        precoP: precoP,
        precoM: precoM,
        precoG: precoG,
        descricao: descricao,
        foto: fotoNova ? fotoNova.name : fotoAtual,
      })
      .then(() => {
        setCarregando(0);
        setMsgTipo('SUCESSO');
      })
      .catch((erro) => {
        setMsgTipo('erro');
        setCarregando(0);
      });
  }

  function cadastrar() {
    setMsgTipo(null);
    setCarregando(1);

    storage
      .ref()
      .child(`img`)
      .child(fotoNova.name)
      .put(fotoNova)
      .then(() => {
        db.collection('produtos')
          .add({
            nome: nome,
            tipo: tipo,
            precoP: precoP,
            precoM: precoM,
            precoG: precoG,
            descricao: descricao,
            usuario: usuarioEmail,
            visualizacoes: 0,
            foto: fotoNova.name,
            criacao: new Date(),
          })
          .then(() => {});
        setCarregando(0);
        setMsgTipo('SUCESSO');
      })
      .catch((erro) => {
        setMsgTipo('erro');
        setCarregando(0);
      });
  }

  return (
    <>
      <div className={styles.publicarProduto}>
        <form className={`${styles.form} animeLeft`}>
          <div className={styles.logTitulo}>
            <h1>{id ? 'Editar Produto' : 'Cadastrar'}</h1>
          </div>
          <Input
            id={nome}
            type="text"
            name="nome"
            onChange={(e) => setNome(e.target.value)}
            label="Nome do produto: "
            value={nome && nome}
          />

          <div>
            <label className={styles.labelSelect} htmlFor="tipo">
              Tipo:
            </label>
            <select
              id={tipo}
              onChange={(e) => setTipo(e.target.value)}
              name="tipo"
              className={styles.select}
              value={tipo && tipo}
            >
              <option disabled selected value="selecione">
                --Selecione--
              </option>
              <option value="Bolos">Bolos</option>
              <option value="Tortas">Tortas</option>
              <option value="Pães Italianos">Pães Italianos</option>
              <option value="Rocomboles">Rocomboles</option>
              <option value="Outros">Outros</option>
            </select>
          </div>

          <div className={styles.preco}>
            <Input
              id={precoP}
              name="precoP"
              onChange={(e) => setPrecoP(e.target.value)}
              label="Preço pequeno: "
              value={precoP && precoP}
            />
            <Input
              id={precoM}
              name="precoM"
              onChange={(e) => setPrecoM(e.target.value)}
              label="Preço médio: "
              value={precoM && precoM}
            />
            <Input
              id={precoG}
              name="precoG"
              onChange={(e) => setPrecoG(e.target.value)}
              label="Preço grande: "
              value={precoG && precoG}
            />
          </div>
          <div>
            <label className={styles.labelTextarea} htmlFor="descricao">
              Descrição do produto
            </label>
            <textarea
              id={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              className={styles.textarea}
              rows="5"
              name="descricao"
              value={descricao && descricao}
            />
          </div>
          <div>
            <label className={styles.labelFile} htmlFor="file">
              Escolha a foto do produto:
              {id
                ? '(Caso queira manter a foto, não precisa escolher uma nova imagem!)'
                : null}
            </label>
            <input
              onChange={(e) => setFotoNova(e.target.files[0])}
              className={styles.file}
              type="file"
              name="file"
              id={fotoNova}
            />
          </div>
          {carregando > 0 ? (
            <Button disabled>Carregando...</Button>
          ) : (
              <>
              {usuarioLogado === usuarioGina ? <Button onClick={id ? atualizar : cadastrar} type="button">
              {id ? 'Editar Produto' : 'Cadastrar'}
            </Button> : <p>"Usuario não permitido"</p>}
             
            </>
          )}
          
        </form>
        <div className={styles.msgLogin}>
          {msgTipo === 'SUCESSO' && (
            <span>
              <strong>Wow! Produto Publicado!</strong>
            </span>
          )}

          {msgTipo === 'erro' && (
            <span>
              <strong>Ops! Não foi possível publicar o evento!</strong>
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default PublicarProduto;
