import React  from 'react';
import InputMask from 'react-input-mask';
import styles from './MaskedInput.module.css';






const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const MaskedImput = ({ value, mask, onChange, label, ...props }) => {
  
  
  const [msgErro] = React.useState(null); 
  
 
  function handleChange(event) {
    // const cpfValido = validate(event.target.value)

  
    // if( value.length === 10 && cpfValido === false  ) {
    //     setMsgErro('CPF Inválido')
    //     setTimeout(() => {
    //       setMsgErro(null)
    //     },2000)
    // }
  
    onChange({
      ...event,
      target: {
        ...event.target,
        value: onlyNumbers(event.target.value),
      },
    });
    
  }

  return (
    <div className={styles.wrapper}>
      <InputMask className={styles.input}
        mask={mask}
        value={value}
        onChange={handleChange}
        {...props}
        label={label}
      />

      <div>{msgErro}</div>

    </div>
  );
};

export default MaskedImput;
