import { createContext, useState, useEffect } from 'react';

export const CartContext = createContext({});

export const CartProvider = ({ children }) => {
  const [produto, setProduto] = useState({});
  const [cart, setCart] = useState([]);
  const [preco, setPreco] = useState([]);
  const [urlImg, setUrlImg] = useState({});
  const [cadastro, setCadastro] = useState(null);
  const [cadastroId, setCadastroId] = useState(null);
  const [cepp, setCepp] = useState('');
  const [cepResultado, setCepResultado] = useState('');
  const [frete, setFrete] = useState(0);
  const [msgFrete, setMsgFrete] = useState(0);
  const [cpf, setCpf] = useState('');
  

 

  

  useEffect(() => {
    if(cepp.length !== 8) return null
    fetch(`https://viacep.com.br/ws/${cepp}/json`)
      .then((response) => response.json())
      .then((body) => {
        setCepResultado(body);
      });
  }, [cepp]);

  const removeFromCart = (productToRemove) => {
    setCart(cart.filter((produto) => produto !== productToRemove));
  };

  useEffect(() => {
    if (cadastro === null) return null;
    cadastro.map((item) => {
      setCadastroId(item);
    });
  });
  

  return (
    <CartContext.Provider
      value={{
        produto,
        setProduto,
        cart,
        setCart,
        removeFromCart,
        preco,
        setPreco,
        urlImg,
        setUrlImg,
        cadastro,
        setCadastro,
        cadastroId,
        cepp,
        setCepp,
        cepResultado,
        setCepResultado,
        frete,
        setFrete,
        msgFrete,
        setMsgFrete,
        setCpf,
        cpf
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
