import React from 'react'


export default function SimpleMap(){


  return (
 
    <div >
         <div className="google-map-code">
          <iframe title={"map"} src="https://maps.google.com/maps?q=rua%20tenisson%20ribeiro%20563&t=&z=13&ie=UTF8&iwloc=&output=embed"  height="450" width="100%" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </div>
    </div>
  );
}
