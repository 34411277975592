import React, { useEffect } from 'react';
import firebase from '../../Utils/Firebase';
import 'firebase/firestore';
import 'firebase/storage';
import styles from './ProdutoDetalhes.module.css';
import { Link, useParams, Navigate } from 'react-router-dom';
import Head from '../Head';
import logoCarregando from '../../Assets/logocantinho.png';
import olho from '../../Assets/eye.svg';
import { useSelector } from 'react-redux';
import Image from '../skeleton/Image';
import { CartContext } from '../../CartContext';
import { useContext } from 'react';
import Aviso from '../forms/Aviso'

const ProdutoDetalhes = () => {
  const { produto, setProduto } = useContext(CartContext);
  const { urlImg, setUrlImg } = useContext(CartContext);
  const [carregando, setCarregando] = React.useState(1);
  const [excluir, setExcluir] = React.useState(0);
  const [msgAdd, setMsgAdd] = React.useState(null);
  const [msgTipo, setMsgTipo] = React.useState(null);
  const [error, setError] = React.useState(null);
  const usuarioLogado = useSelector((state) => state.usuarioEmail);
  const { cart, setCart } = useContext(CartContext);
  const { preco, setPreco } = useContext(CartContext);

  const parans = useParams();
  const id = parans.id;

  useEffect(() => {
    if (carregando) {
      setPreco([]);
    }
  }, [carregando]);

  function remover() {
    firebase
      .firestore()
      .collection('produtos')
      .doc(id)
      .delete()
      .then(() => {
        setExcluir(1);
      });
  }

  React.useEffect(() => {
    try {
      firebase
        .firestore()
        .collection('produtos')
        .doc(id)
        .get()
        .then((resultado) => {
          setProduto(resultado.data());
          firebase
            .firestore()
            .collection('produtos')
            .doc(id)
            .update('visualizacoes', resultado.data().visualizacoes + 1);

          firebase
            .storage()
            .ref(`img/${resultado.data().foto}`)
            .getDownloadURL()
            .then((url) => {
              setUrlImg(url);
              setCarregando(0);
            });
        });
    } catch (erro) {
      setError('Um erro ocorreu!');
    }
  }, []);

  const addToCart = (product) => {
    if (preco.length === 0) {
      setMsgTipo('ok')
      setMsgAdd('Selecione o tamanho');
      setTimeout(() => {
        setMsgTipo(null)
        setMsgAdd(null);
      }, 3000)
      return;
    } else {
      const nArrayId = new Array(id)
      const nId ={id: nArrayId};
      const nArray = new Array(preco);
      const price = { price: nArray };
      const nfoto = new Array(urlImg);
      const fotoSidebar = { fotoSidebar: nfoto };
      setCart([...cart, { ...product,...nId, ...price, ...fotoSidebar }])
    }
    if(cart.length >= 0) {
      setMsgTipo('ok')
      setMsgAdd("Adicionado ao carrinho");
      setTimeout(() => {
        setMsgAdd(null)
      setMsgTipo(null)
      },2000)
    }
  };

  

  function handleChange({ target }) {
    setPreco(target.value);
  }

  if (error) return <p>{error}</p>;
  if (produto === null) return null;
  return (
    <>
      <div className={styles.produtoDetalhes}>
        {excluir > 0 ? <Navigate to="/" /> : null}
        {carregando ? (
          <img src={logoCarregando} alt={'Logo cantinho'} />
        ) : (
          <div className={`${styles.contentDescricao} animeLeft`}>
            <Head
              title={`Cantinho d' Bolos | ${produto.nome}`}
              description="Esses São nossos produtos."
            />

            <div>
              <h1 className={styles.title}>{produto.nome}</h1>
              <Image className={styles.img} src={urlImg} alt="foto" />
            </div>

            <div className={styles.descricao}>
              <p className={styles.descricaoParagrafo}>{produto.descricao}</p>
              
              <div>
                <div className={styles.gridPrecosContent}>
                  <label className={styles.gridPrecos}>
                    <input
                      name="price"
                      value={produto.precoP}
                      onChange={handleChange}
                      type="radio"
                    />
                    <p>Pequeno</p> R${' '}
                    <p>
                      <span className={styles.preco}>{produto.precoP}</span>
                    </p>
                  </label>
                  
                  <label className={styles.gridPrecos}>
                    <input
                      name="price"
                      value={produto.precoM}
                      onChange={handleChange}
                      type="radio"
                    />
                    <p>Médio</p> R${' '}
                    <p>
                      {' '}
                      <span className={styles.preco}>{produto.precoM}</span>
                    </p>
                  </label>

                  <label className={styles.gridPrecos}>
                    <input
                      name="price"
                      value={produto.precoG}
                      onChange={handleChange}
                      type="radio"
                    />
                    <p>Grande</p> R${' '}
                    <p>
                      <span className={styles.preco}>{produto.precoG}</span>
                    </p>
                  </label>
                </div>

                {usuarioLogado === produto.usuario ? (
                  <>
                    <div className={styles.olhoContent}>
                      <div className={styles.olho}>
                        <img src={olho} alt="logo Olho" />
                      </div>
                      <div>
                        <p>{produto.visualizacoes}</p>
                      </div>
                    </div>
                  </>
                ) : (
                  null
                )}

                {usuarioLogado === produto.usuario ? (
                  <>
                    <div className={styles.btns}>
                      <button className={styles.btnEdt} onClick={remover}>
                        {' '}
                        Remover
                      </button>

                      <Link
                        to={`/editarproduto/${id}`}
                        className={` ${styles.editar}`}
                      >
                        Editar
                      </Link>
                    </div>
                  </>
                ) : (
                  null
                )}
              </div>
              <div className={styles.btnAddCart}>
                {preco ? (
                  <button
                    className={styles.btnAdd}
                    onClick={() => addToCart(produto)}
                  >
                    Adicionar ao Carrinho
                  </button>
                ) : (
                  <h2 className={styles.paragrafoTamanhoValido}>"Selecione um tamanho válido"</h2>
                )}
              </div>
              {msgTipo === 'ok' ?  <Aviso texto={msgAdd} /> : null}
              
            </div>
          </div>
        )}

          



      </div>
    </>
  );
};

export default ProdutoDetalhes;
