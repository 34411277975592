import React from 'react'
import styles from './Regras.module.css'

const Regras = () => {
  return (
    <div>
      <h1 className={styles.titulo}>Regras</h1>
      <ul className={styles.regras}>
        <li>1- A pontuação somente será gerada, a cada <strong>R$ 20,00 em compra completo.</strong> Não sendo qualquer fração menor que esse valor, considerada.</li>
        <li>2- A cada um ponto você ganha R$ 1,00 (um real) de desconto.</li>
        <li>3- O desconto pode ser acomulativo, com prazo de <strong>Validade</strong> de até seis meses.</li>
        <li>4- Sendo assim, a pontuação será <strong>zerada</strong> nas respectivas datas 01/01 e 01/07.</li>
        <li>5- A ultilização dos pontos deve ser total, não podendo ser ultilizado fração do mesmo.</li>
       
      </ul>
    </div>
  )
}

export default Regras
