import React from 'react'
import styles from './Aviso.module.css'

const Aviso = ({texto}) => {
  return (
    
      <p className={styles.aviso}>
       {texto}
      </p>
    
  )
}

export default Aviso
